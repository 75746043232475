.tabulator {
  position: relative;
  font-size: 14px;
  text-align: left;
  overflow: hidden;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}

.tabulator input,
.tabulator select {
  background-color: rgba(200, 220, 255, 0.1);
  border-radius: 5px;
  border-color: rgba(209, 213, 219, 0.3);
}

.tabulator[tabulator-layout="fitDataFill"]
  .tabulator-tableholder
  .tabulator-table {
  min-width: 100%;
}

.tabulator[tabulator-layout="fitDataTable"] {
  display: inline-block;
}

.tabulator.tabulator-block-select {
  user-select: none;
}

.tabulator .tabulator-header {
  position: relative;
  width: 100%;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.tabulator .tabulator-header.tabulator-header-hidden {
  display: none;
}

.tabulator .tabulator-header .tabulator-header-contents {
  position: relative;
  overflow: hidden;
}

.tabulator .tabulator-header .tabulator-header-contents .tabulator-headers {
  display: inline-block;
}

.tabulator .tabulator-header .tabulator-col {
  display: inline-flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  vertical-align: bottom;
  overflow: hidden;
}

.tabulator .tabulator-header .tabulator-col.tabulator-moving {
  position: absolute;
  pointer-events: none;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content {
  position: relative;
  padding: 4px;
}

.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-col-content
  .tabulator-header-popup-button {
  padding: 0 8px;
}

.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-col-content
  .tabulator-header-popup-button:hover {
  cursor: pointer;
  opacity: 0.6;
}

.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-col-content
  .tabulator-col-title-holder {
  position: relative;
}

.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-col-content
  .tabulator-col-title {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
}

.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-col-content
  .tabulator-col-title.tabulator-col-title-wrap {
  white-space: normal;
  text-overflow: clip;
}

.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-col-content
  .tabulator-col-title
  .tabulator-title-editor {
  width: 100%;
  background: #fff;
  padding: 1px;
}

.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-col-content
  .tabulator-col-title
  .tabulator-header-popup-button
  + .tabulator-title-editor {
  width: calc(100% - 22px);
}

.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-col-content
  .tabulator-col-sorter {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 4px;
}

.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-col-content
  .tabulator-col-sorter
  .tabulator-arrow {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #bbb;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-col-group
  .tabulator-col-group-cols {
  position: relative;
  display: flex;
  overflow: hidden;
  margin-right: -1px;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter {
  position: relative;
  margin-top: 2px;
  width: 100%;
  text-align: center;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter textarea {
  height: auto !important;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter svg {
  margin-top: 3px;
}

.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-header-filter
  input::-ms-clear {
  width: 0;
  height: 0;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable
  .tabulator-col-title {
  padding-right: 25px;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable.tabulator-col-sorter-element:hover {
  cursor: pointer;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable[aria-sort="none"]
  .tabulator-col-content
  .tabulator-col-sorter
  .tabulator-arrow {
  border-top: none;
  border-bottom: 6px solid #bbb;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable[aria-sort="ascending"]
  .tabulator-col-content
  .tabulator-col-sorter
  .tabulator-arrow {
  border-top: none;
  border-bottom: 6px solid #666;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable[aria-sort="descending"]
  .tabulator-col-content
  .tabulator-col-sorter
  .tabulator-arrow {
  border-bottom: none;
  border-top: 6px solid #666;
  color: #666;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-col-vertical
  .tabulator-col-content
  .tabulator-col-title {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-col-vertical.tabulator-col-vertical-flip
  .tabulator-col-title {
  transform: rotate(180deg);
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-col-vertical.tabulator-sortable
  .tabulator-col-title {
  padding-right: 0;
  padding-top: 20px;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-col-vertical.tabulator-sortable.tabulator-col-vertical-flip
  .tabulator-col-title {
  padding-right: 0;
  padding-bottom: 20px;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-col-vertical.tabulator-sortable
  .tabulator-col-sorter {
  justify-content: center;
  left: 0;
  right: 0;
  top: 4px;
  bottom: auto;
}

.tabulator .tabulator-header .tabulator-frozen {
  position: sticky;
  left: 0;
  z-index: 10;
}

.tabulator
  .tabulator-header
  .tabulator-calcs-holder
  .tabulator-row
  .tabulator-col-resize-handle,
.tabulator .tabulator-header .tabulator-frozen-rows-holder:empty {
  display: none;
}

.tabulator .tabulator-tableholder {
  position: relative;
  width: 100%;
  white-space: nowrap;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.tabulator .tabulator-tableholder:focus {
  outline: none;
}

.tabulator .tabulator-tableholder .tabulator-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.tabulator
  .tabulator-tableholder
  .tabulator-placeholder[tabulator-render-mode="virtual"] {
  min-height: 100%;
  min-width: 100%;
}

.tabulator
  .tabulator-tableholder
  .tabulator-placeholder
  .tabulator-placeholder-contents {
  display: inline-block;
  text-align: center;
  padding: 10px;
  font-weight: 700;
  font-size: 20px;
  white-space: normal;
}

.tabulator .tabulator-tableholder .tabulator-table {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  overflow: visible;
}

.tabulator
  .tabulator-tableholder
  .tabulator-table
  .tabulator-row.tabulator-calcs {
  font-weight: 700;
}

.tabulator .tabulator-footer {
  font-weight: 700;
  white-space: nowrap;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.tabulator .tabulator-footer .tabulator-footer-contents {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
}

.tabulator .tabulator-footer .tabulator-footer-contents:empty {
  display: none;
}

.tabulator .tabulator-footer .tabulator-calcs-holder {
  width: 100%;
  text-align: left;
  overflow: hidden;
}

.tabulator .tabulator-footer .tabulator-calcs-holder .tabulator-row {
  display: inline-block;
}

.tabulator
  .tabulator-footer
  .tabulator-calcs-holder
  .tabulator-row
  .tabulator-col-resize-handle {
  display: none;
}

.tabulator .tabulator-footer .tabulator-calcs-holder:only-child {
  margin-bottom: -5px;
}

.tabulator .tabulator-footer > * + .tabulator-page-counter {
  margin-left: 10px;
}

.tabulator .tabulator-footer .tabulator-page-counter {
  font-weight: 400;
}

.tabulator .tabulator-footer .tabulator-paginator {
  flex: 1;
  text-align: right;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
}

.tabulator .tabulator-footer .tabulator-page-size {
  display: inline-block;
  margin: 0 5px;
  padding: 2px 5px;
}

.tabulator .tabulator-footer .tabulator-pages {
  margin: 0 7px;
}

.tabulator .tabulator-footer .tabulator-page {
  display: inline-block;
  margin: 0 2px;
  padding: 5px 8px;
  background-color: rgba(200, 220, 255, 0.1);
  border-radius: 4px;
}

.tabulator .tabulator-footer .tabulator-page:disabled {
  opacity: 0.5;
}

.tabulator .tabulator-footer .tabulator-page:not(.disabled):hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.2);
}

.tabulator .tabulator-col-resize-handle {
  position: relative;
  display: inline-block;
  width: 6px;
  margin-left: -3px;
  margin-right: -3px;
  z-index: 10;
  vertical-align: middle;
}

.tabulator .tabulator-col-resize-handle:hover {
  cursor: ew-resize;
}

.tabulator .tabulator-col-resize-handle:last-of-type {
  width: 3px;
  margin-right: 0;
}

.tabulator .tabulator-alert {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  text-align: center;
}

.tabulator .tabulator-alert .tabulator-alert-msg {
  display: inline-block;
  margin: 0 auto;
  padding: 10px 20px;
  font-weight: 700;
  font-size: 16px;
}

.tabulator-row {
  position: relative;
  min-height: 22px;
}

.tabulator-row.tabulator-selectable:hover {
  cursor: pointer;
}

.tabulator-row.tabulator-selected:hover {
  cursor: pointer;
}

.tabulator-row.tabulator-moving {
  position: absolute;
  pointer-events: none;
  z-index: 15;
}

.tabulator-row .tabulator-row-resize-handle {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 5px;
}

.tabulator-row .tabulator-row-resize-handle.prev {
  top: 0;
  bottom: auto;
}

.tabulator-row .tabulator-row-resize-handle:hover {
  cursor: ns-resize;
}

.tabulator-row .tabulator-responsive-collapse {
  padding: 5px;
}

.tabulator-row .tabulator-responsive-collapse:empty {
  display: none;
}

.tabulator-row .tabulator-responsive-collapse table {
  font-size: 14px;
}

.tabulator-row .tabulator-responsive-collapse table tr td {
  position: relative;
}

.tabulator-row .tabulator-responsive-collapse table tr td:first-of-type {
  padding-right: 10px;
}

.tabulator-row .tabulator-cell {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tabulator-row .tabulator-cell.tabulator-frozen {
  display: inline-block;
  position: sticky;
  left: 0;
  z-index: 10;
}

.tabulator-row .tabulator-cell.tabulator-editing {
  outline: none;
  padding: 0;
}

.tabulator-row .tabulator-cell.tabulator-editing input,
.tabulator-row .tabulator-cell.tabulator-editing select {
  background: transparent;
  outline: none;
}

.tabulator-row .tabulator-cell.tabulator-validation-fail input,
.tabulator-row .tabulator-cell.tabulator-validation-fail select {
  background: transparent;
}

.tabulator-row .tabulator-cell.tabulator-row-handle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.tabulator-row .tabulator-cell.tabulator-row-handle .tabulator-row-handle-box {
  width: 80%;
}

.tabulator-row
  .tabulator-cell.tabulator-row-handle
  .tabulator-row-handle-box
  .tabulator-row-handle-bar {
  width: 100%;
  height: 3px;
  margin-top: 2px;
  background: #666;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-branch {
  display: inline-block;
  vertical-align: middle;
  height: 9px;
  width: 7px;
  margin-top: -9px;
  margin-right: 5px;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  height: 11px;
  width: 11px;
  margin-right: 5px;
  background: rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.2);
}

.tabulator-row
  .tabulator-cell
  .tabulator-data-tree-control
  .tabulator-data-tree-control-collapse {
  display: inline-block;
  position: relative;
  height: 7px;
  width: 1px;
  background: transparent;
}

.tabulator-row
  .tabulator-cell
  .tabulator-data-tree-control
  .tabulator-data-tree-control-collapse:after {
  position: absolute;
  content: "";
  left: -3px;
  top: 3px;
  height: 1px;
  width: 7px;
  background: #333;
}

.tabulator-row
  .tabulator-cell
  .tabulator-data-tree-control
  .tabulator-data-tree-control-expand {
  display: inline-block;
  position: relative;
  height: 7px;
  width: 1px;
  background: #333;
}

.tabulator-row
  .tabulator-cell
  .tabulator-data-tree-control
  .tabulator-data-tree-control-expand:after {
  position: absolute;
  content: "";
  left: -3px;
  top: 3px;
  height: 1px;
  width: 7px;
  background: #333;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  height: 15px;
  width: 15px;
  font-weight: 700;
  font-size: 1.1em;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle:hover {
  opacity: 0.7;
  cursor: pointer;
}

.tabulator-row
  .tabulator-cell
  .tabulator-responsive-collapse-toggle.open
  .tabulator-responsive-collapse-toggle-close {
  display: initial;
}

.tabulator-row
  .tabulator-cell
  .tabulator-responsive-collapse-toggle.open
  .tabulator-responsive-collapse-toggle-open {
  display: none;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle svg {
  stroke: #fff;
}

.tabulator-row
  .tabulator-cell
  .tabulator-responsive-collapse-toggle
  .tabulator-responsive-collapse-toggle-close {
  display: none;
}

.tabulator-row .tabulator-cell .tabulator-traffic-light {
  display: inline-block;
  height: 14px;
  width: 14px;
}

.tabulator-row.tabulator-group {
  padding: 5px 5px 5px 10px;
  font-weight: 700;
  min-width: 100%;
}

.tabulator-row.tabulator-group:hover {
  cursor: pointer;
}

.tabulator-row.tabulator-group.tabulator-group-visible .tabulator-arrow {
  margin-right: 10px;
}

.tabulator-row.tabulator-group.tabulator-group-level-1 {
  padding-left: 30px;
}

.tabulator-row.tabulator-group.tabulator-group-level-2 {
  padding-left: 50px;
}

.tabulator-row.tabulator-group.tabulator-group-level-3 {
  padding-left: 70px;
}

.tabulator-row.tabulator-group.tabulator-group-level-4 {
  padding-left: 90px;
}

.tabulator-row.tabulator-group.tabulator-group-level-5 {
  padding-left: 110px;
}

.tabulator-row.tabulator-group .tabulator-group-toggle {
  display: inline-block;
}

.tabulator-row.tabulator-group .tabulator-arrow {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 16px;
  vertical-align: middle;
}

.tabulator-row.tabulator-group span {
  margin-left: 10px;
}

.tabulator-popup-container {
  position: absolute;
  display: inline-block;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  font-size: 14px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 10000;
}

.tabulator-popup {
  padding: 5px;
}

.tabulator-menu .tabulator-menu-item {
  position: relative;
  padding: 5px 10px;
  user-select: none;
}

.tabulator-menu .tabulator-menu-item.tabulator-menu-item-disabled {
  opacity: 0.5;
}

.tabulator-menu .tabulator-menu-item:not(.tabulator-menu-item-disabled):hover {
  cursor: pointer;
}

.tabulator-menu .tabulator-menu-item.tabulator-menu-item-submenu {
  padding-right: 25px;
}

.tabulator-menu .tabulator-menu-item.tabulator-menu-item-submenu:after {
  display: inline-block;
  position: absolute;
  top: calc(5px + 0.4em);
  right: 10px;
  height: 7px;
  width: 7px;
  content: "";
  vertical-align: top;
  transform: rotate(45deg);
}

.tabulator-edit-list {
  max-height: 200px;
  font-size: 14px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.tabulator-edit-list .tabulator-edit-list-item {
  padding: 4px;
  outline: none;
}

.tabulator-edit-list .tabulator-edit-list-item.active {
  background: #1263e0;
}

.tabulator-edit-list .tabulator-edit-list-item.active.focused {
  outline: 1px solid hsla(0, 0%, 100%, 0.5);
}

.tabulator-edit-list .tabulator-edit-list-item.focused {
  outline: 1px solid #1d68cd;
}

.tabulator-edit-list .tabulator-edit-list-item:hover {
  cursor: pointer;
  background: #8bb5f6;
}

.tabulator-edit-list .tabulator-edit-list-placeholder {
  padding: 4px;
  text-align: center;
}

.tabulator-edit-list .tabulator-edit-list-group {
  padding: 6px 4px 4px;
  font-weight: 700;
}

.tabulator-edit-list
  .tabulator-edit-list-group.tabulator-edit-list-group-level-2,
.tabulator-edit-list
  .tabulator-edit-list-item.tabulator-edit-list-group-level-2 {
  padding-left: 12px;
}

.tabulator-edit-list
  .tabulator-edit-list-group.tabulator-edit-list-group-level-3,
.tabulator-edit-list
  .tabulator-edit-list-item.tabulator-edit-list-group-level-3 {
  padding-left: 20px;
}

.tabulator-edit-list
  .tabulator-edit-list-group.tabulator-edit-list-group-level-4,
.tabulator-edit-list
  .tabulator-edit-list-item.tabulator-edit-list-group-level-4 {
  padding-left: 28px;
}

.tabulator-edit-list
  .tabulator-edit-list-group.tabulator-edit-list-group-level-5,
.tabulator-edit-list
  .tabulator-edit-list-item.tabulator-edit-list-group-level-5 {
  padding-left: 36px;
}

.tabulator.tabulator-ltr {
  direction: ltr;
}

.tabulator.tabulator-rtl {
  text-align: initial;
  direction: rtl;
}

.tabulator.tabulator-rtl .tabulator-header .tabulator-col {
  text-align: initial;
}

.tabulator.tabulator-rtl
  .tabulator-header
  .tabulator-col.tabulator-col-group
  .tabulator-col-group-cols {
  margin-right: 0;
  margin-left: -1px;
}

.tabulator.tabulator-rtl
  .tabulator-header
  .tabulator-col.tabulator-sortable
  .tabulator-col-title {
  padding-right: 0;
  padding-left: 25px;
}

.tabulator.tabulator-rtl
  .tabulator-header
  .tabulator-col
  .tabulator-col-content
  .tabulator-col-sorter {
  left: 8px;
  right: auto;
}

.tabulator.tabulator-rtl
  .tabulator-row
  .tabulator-cell
  .tabulator-data-tree-branch {
  margin-right: 0;
  margin-left: 5px;
}

.tabulator.tabulator-rtl
  .tabulator-row
  .tabulator-cell
  .tabulator-data-tree-control {
  margin-right: 0;
  margin-left: 5px;
}

.tabulator.tabulator-rtl
  .tabulator-row
  .tabulator-col-resize-handle:last-of-type {
  width: 3px;
  margin-left: 0;
  margin-right: -3px;
}

.tabulator.tabulator-rtl .tabulator-footer .tabulator-calcs-holder {
  text-align: initial;
}

.tabulator-print-fullscreen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
}

body.tabulator-print-fullscreen-hide > :not(.tabulator-print-fullscreen) {
  display: none !important;
}

.tabulator-print-table .tabulator-data-tree-branch {
  display: inline-block;
  vertical-align: middle;
  height: 9px;
  width: 7px;
  margin-top: -9px;
  margin-right: 5px;
}

.tabulator-print-table .tabulator-print-table-group {
  padding: 5px 5px 5px 10px;
  font-weight: 700;
  min-width: 100%;
}

.tabulator-print-table .tabulator-print-table-group:hover {
  cursor: pointer;
}

.tabulator-print-table
  .tabulator-print-table-group.tabulator-group-visible
  .tabulator-arrow {
  margin-right: 10px;
}

.tabulator-print-table .tabulator-print-table-group.tabulator-group-level-1 td {
  padding-left: 30px !important;
}

.tabulator-print-table .tabulator-print-table-group.tabulator-group-level-2 td {
  padding-left: 50px !important;
}

.tabulator-print-table .tabulator-print-table-group.tabulator-group-level-3 td {
  padding-left: 70px !important;
}

.tabulator-print-table .tabulator-print-table-group.tabulator-group-level-4 td {
  padding-left: 90px !important;
}

.tabulator-print-table .tabulator-print-table-group.tabulator-group-level-5 td {
  padding-left: 110px !important;
}

.tabulator-print-table .tabulator-print-table-group .tabulator-group-toggle {
  display: inline-block;
}

.tabulator-print-table .tabulator-print-table-group .tabulator-arrow {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 16px;
  vertical-align: middle;
}

.tabulator-print-table .tabulator-data-tree-control {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  height: 11px;
  width: 11px;
  margin-right: 5px;
  background: rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.tabulator-print-table .tabulator-data-tree-control:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.2);
}

.tabulator-print-table
  .tabulator-data-tree-control
  .tabulator-data-tree-control-collapse {
  display: inline-block;
  position: relative;
  height: 7px;
  width: 1px;
  background: transparent;
}

.tabulator-print-table
  .tabulator-data-tree-control
  .tabulator-data-tree-control-collapse:after {
  position: absolute;
  content: "";
  left: -3px;
  top: 3px;
  height: 1px;
  width: 7px;
  background: #333;
}

.tabulator-print-table
  .tabulator-data-tree-control
  .tabulator-data-tree-control-expand {
  display: inline-block;
  position: relative;
  height: 7px;
  width: 1px;
  background: #333;
}

.tabulator-print-table
  .tabulator-data-tree-control
  .tabulator-data-tree-control-expand:after {
  position: absolute;
  content: "";
  left: -3px;
  top: 3px;
  height: 1px;
  width: 7px;
  background: #333;
}

.tabulator-print-table .tabulator-print-table-group span {
  margin-left: 10px;
}
